<template>
    <div class="answer-detail">
        <div class="answer-detail-header">
            <div>
                班级
                <el-select v-model="classValue" placeholder="请选择" style="margin-left: 8px" @change="selectClass">
                    <el-option
                            v-for="item in classList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <el-button type="primary" @click="toBack">返回</el-button>
        </div>
        <el-scrollbar class="answer-detail-table">
            <div class="detail-table-header">
                <div class="table-header-item"><span>学生名称</span></div>
                <div class="table-header-item"><span>班级名称</span></div>
                <div class="table-header-item"><span>训练次数</span></div>
                <div class="table-header-item"><span>操作</span></div>
            </div>
            <div class="detail-table-body" v-if="studentList.length">
                <div class="table-body-item" v-for="item in studentList">
                    <div class="body-item-top">
                        <div class="item-top-item">
                            <i class="el-icon-arrow-right" :style="item.isShow ? 'transform:rotate(90deg)' : ''"
                               @click="item.isShow = !item.isShow"></i>
                            <span>{{item.stu_name}}</span>
                        </div>
                        <div class="item-top-item"><span>{{item.class_name}}</span></div>
                        <div class="item-top-item"><span>共{{item.drill_num}}次</span></div>
                        <div class="item-top-item"><span></span></div>
                    </div>
                    <template v-if="item.isShow">
                        <div class="body-item-bottom" v-for="itemChildren in item.drill_result">
                            <div class="item-bottom-item"><span></span></div>
                            <div class="item-bottom-item"><span></span></div>
                            <div class="item-bottom-item"><span>第{{itemChildren.time}}次</span></div>
                            <div class="item-bottom-item"><span @click="toDetail(itemChildren)">查看详情</span></div>
                        </div>
                    </template>
                </div>
            </div>
            <div v-else style="line-height: 70px;text-align: center">暂无数据</div>
        </el-scrollbar>
        <el-pagination class="pager-center" style="margin: 20px;text-align: center"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       :total="listPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="listCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {show_drill_stu_detail, getClassesList} from '@/utils/apis'

    export default {
        name: "AnswerDetail",
        data() {
            return {
                classValue: '',
                classList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                isShow: true,
                moduleId: Number(this.$route.query.moduleId) || null,
                studentList: [],
            }
        },
        mounted() {
            this.getClassesData();
            this.getStudentList();
        },
        methods: {
            selectClass() {
                this.listPages.currentPageNum = 1;
                this.getStudentList()
            },
            getClassesData() {
                getClassesList().then((res) => {
                    this.classList = res.data;
                }).catch((err) => {
                    console.log('err', err)
                })
            },
            getStudentList() {
                let params = {
                    op_id: this.$route.query.id,
                    course_id: this.$route.query.course_id,
                    chapter: this.$route.query.chapter,
                    node: this.$route.query.node,
                }
                if (this.classValue) {
                    params.class_id = this.classValue
                }
                show_drill_stu_detail(params).then((res) => {
                    if (res.data.data.length) {
                        res.data.data.forEach(item => {
                            this.$set(item, 'isShow', true)
                        })
                    }
                    this.studentList = res.data.data;
                    this.listPages.total = res.data.total;
                }).catch((err) => {
                    console.log('err', err)
                })
            },
            // 查看详情
            toDetail(item) {
                console.log('item', item);
                if (this.moduleId === 42 || this.moduleId === 43) {
                    // 网络客户服务
                    this.$router.push({
                        path: '/trainingCenter/practicalTraining/cyberCustomerDetail',
                        query: {
                            moduleId: this.moduleId,
                            submit_id: item.submit_id
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/trainingCenter/practicalTraining/lookStudentDetail'
                    })
                }
            },
            listCurrentChange(val) {
                this.listPages.currentPageNum = val;
                this.getStudentList();
            },
            toBack() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style scoped lang="scss">
    .answer-detail {
        padding: 0px 20px;
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-direction: column;

        .answer-detail-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .answer-detail-table {
            border: 1px solid #EEEEEE;
            box-sizing: border-box;
            width: 100%;
            flex: 1;
            height: 1%;
            margin-top: 20px;
            display: flex;
            flex-direction: column;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .detail-table-header {
                display: flex;
                background: #F6F6F6;
                height: 60px;
                line-height: 60px;
                box-sizing: border-box;

                .table-header-item {
                    width: 25%;
                    text-align: center;
                    box-sizing: border-box;

                    &:first-of-type {
                        text-align: left;
                        padding-left: 58px;
                    }

                    span {
                        color: #666666;
                        font-size: 16px;
                    }
                }
            }

            .detail-table-body {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;

                .table-body-item {
                    display: flex;
                    flex-direction: column;

                    .body-item-top, .body-item-bottom {
                        box-sizing: border-box;
                        width: 100%;
                        display: flex;
                        border-bottom: 1px solid #EEEEEE;

                        .item-top-item, .item-bottom-item {
                            width: 25%;
                            height: 70px;
                            line-height: 70px;
                            text-align: center;
                            box-sizing: border-box;

                            &:first-of-type {
                                text-align: left;
                                padding-left: 26px;

                                i {
                                    cursor: pointer;
                                    color: #333333;
                                    font-weight: bold;
                                }

                                span {
                                    margin-left: 18px;
                                }
                            }

                            span {
                                color: #333333;
                                font-size: 16px;
                            }

                            &.item-bottom-item {
                                &:last-of-type {
                                    span {
                                        color: #1CB064;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>